<template>
  <TsNuxtCard append-class="p-1 m-1 w-48 bg-idle-white flex-none" no-border>
    <TsRow append-class="justify-center pt-4">
      <NuxtLinkWithLocale
        :to="`/product/${product?.slug}-${product?.code}`"
        :data-testid="props.dataTestid + '-details-page-link-on-image'"
      >
        <NuxtImg
          width="105"
          height="105"
          class="rounded-xl bg-white"
          :alt="product.name"
          :data-testid="props.dataTestid + '-image'"
          loading="lazy"
          :src="product.image"
        />
      </NuxtLinkWithLocale>
    </TsRow>
    <TsTypography
      as="p"
      size="xs"
      :data-testid="props.dataTestid + '-code'"
      append-class="text-right mr-2"
      >ID: {{ product.code }}</TsTypography
    >

    <TsColumn>
      <NuxtLinkWithLocale
        :to="`/product/${product?.slug}-${product?.code}`"
        class="mb-0 text-idle-black hover:underline"
        :data-testid="props.dataTestid + '-details-page-link-on-name'"
      >
        <TsTypography
          v-if="product.full_name"
          :data-testid="props.dataTestid + '-full-name'"
        >
          <span class="font-bold">{{ product.full_name.split(" ")[0] }}</span>
          {{ product.full_name?.split(" ").slice(1).join(" ") }}
        </TsTypography>
        <TsTypography
          v-else-if="product.name"
          :data-testid="props.dataTestid + '-name'"
        >
          {{ product.name }}
        </TsTypography>
        <TsTypography v-else :data-testid="props.dataTestid + '-with-no-name'">
          Lorem ipsum dolor sit amet consectetur adipisicing
        </TsTypography>
      </NuxtLinkWithLocale>
      <TsRow append-class="m-0 justify-between">
        <TsTypography
          v-if="
            product.prices &&
            product.prices?.raw &&
            product.prices?.was_price_formatted &&
            product.prices?.raw.gross < product.prices.was_price_raw
          "
          size="sm"
          append-class="mb-0 line-through leading-tight mt-1"
          :data-testid="props.dataTestid + '-recommended-retail-price'"
        >
          {{ product.prices?.was_price_formatted }}
        </TsTypography>

        <TsRatings
          v-if="productRatings >= 0"
          :modelValue="productRatings"
          :label="`(${productReviews})`"
          :read-only="true"
          :stars="5"
          :data-testid="props.dataTestid + '-rating'"
        />
      </TsRow>
      <TsRow append-class="m-0">
        <TsTypography
          variant="h2"
          weight="bold"
          append-class="text-idle-black leading-normal mb-0 leading-tight"
          :data-testid="props.dataTestid + '-vat-price-primary'"
        >
          {{
            rootStore.isIncVat
              ? product.prices?.formatted.gross
              : product.prices?.formatted.net
          }}
        </TsTypography>
        <TsTypography
          v-if="product.prices && product.prices.formatted.per_unit"
          append-class="mt-1 ml-2"
          size="xs"
          :data-testid="props.dataTestid + '-per-unit-price'"
        >
          {{ product.prices?.formatted.per_unit }}
        </TsTypography>
      </TsRow>
      <TsTypography
        size="xs"
        append-class="leading-tight"
        :data-testid="props.dataTestid + '-vat-price-secondary'"
        >{{ rootStore.isIncVat ? "Excl." : "Inc." }} VAT
        {{
          rootStore.isIncVat
            ? product.prices?.formatted.net
            : product.prices?.formatted.gross
        }}
      </TsTypography>
      <TsButton
        icon="bx:cart"
        size="sm"
        :label="addToTrolleyLabel"
        append-class="mt-3"
        outlined
        block
        :data-testid="props.dataTestid + '-add-to-trolley-cta'"
        @click="emit('addToTrolley')"
      />
    </TsColumn>
  </TsNuxtCard>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";
import type { Product } from "~/types/ecom/product/product.type";

const productAttributeStore = useProductAttributeStore();
const rootStore = useRootStore();

const props = defineProps<{
  product: Product;
  dataTestid: string;
  addToTrolleyLabel?: string;
}>();

const emit = defineEmits(["addToTrolley"]);

const productReviews = ref(
  productAttributeStore.getNumberOfReviews(props.product?.other_attributes!) ||
    0
);

function convertToNearestWholeNumber(num: number) {
  const firstDecimalPlace = Math.floor(num * 10) % 10;
  if (firstDecimalPlace < 5) {
    return Math.floor(num);
  } else {
    return Math.ceil(num);
  }
}

const productRatings = ref(
  convertToNearestWholeNumber(
    Number(
      productAttributeStore.getProductRating(props.product?.other_attributes!)
    )
  )
);
</script>
